<template>
  <v-row class="registration-box pl-5 pl-md-2">
    <v-col md="12" class="titles-mobile">
      <v-col class="pa-0" md="6" cols="12">
        <span class="titles" md="6">
          Dados cadastrais
        </span>
      </v-col>
    </v-col>
    <v-col md="6" class="pb-0">
      <v-text-field
        v-model="registration.document"
        label="CNPJ"
        v-mask="'##.###.###/####-##'"
        readonly
      />
    </v-col>
    <v-col md="6" class="pb-0">
      <v-text-field v-model="registration.nickName" label="Nome Fantasia" readonly />
    </v-col>
    <v-col md="6" class="pb-0 pt-0">
      <v-text-field v-model="registration.name" label="Razão Social" readonly />
    </v-col>
    <v-col md="6" class="pb-0 pt-0" v-if="$vuetify.breakpoint.mdAndUp">
      <v-row class="input-subscription">
        <v-text-field
          v-model.lazy="registration.stateSubscription"
          label="Inscrição Estadual"
          readonly
          class="field-subscription"
        />
        <input :checked="checkedRadio" type="radio" name="ritem" class="select-radio" />
        <label for="ritema" class="text-radio">Isento</label>
      </v-row>
    </v-col>
    <v-col cols="12" v-else>
      <v-row>
        <v-col cols="8" class="pa-0 pl-4">
          <v-text-field
            v-model.lazy="registration.stateSubscription"
            label="Inscrição Estadual"
            readonly
          />
        </v-col>
        <v-col cols="4" class="pa-0">
          <v-btn
            class="form-inline"
            :disabled="disabledCheck"
            @click="alterRadio"
            :outlined="!checkedRadio"
            :dark="checkedRadio"
            rounded
            color="#8C8C8C"
            >Isento</v-btn
          >
        </v-col>
      </v-row>
    </v-col>
    <v-col md="6" class="pb-0 pt-0">
      <v-text-field v-model="concatAdress" label="Endereço" readonly />
    </v-col>
    <v-col md="12" class="titles-mobile mt-5">
      <v-col class="pa-0" md="6">
        <span class="titles" md="6">
          Perfil da empresa
        </span>
      </v-col>
    </v-col>
    <v-col md="6" class="pb-0">
      <v-text-field v-model="profile" label="Perfil da empresa" readonly />
    </v-col>
    <v-col md="6" class="pb-0">
      <v-text-field
        v-if="registration.segment"
        v-model="registration.segment.value"
        label="Segmento"
        readonly
      />
    </v-col>
    <v-col md="6" class="pb-0 pt-0">
      <v-text-field v-model="registration.monthlyIncome" label="Faturamento Mensal" readonly />
    </v-col>
    <v-col md="6" class="pb-0 pt-0">
      <v-row>
        <v-col md="6" class="pb-0 pt-0">
          <v-text-field
            masked="####-####-####-####"
            v-model="this.registration.phone"
            label="Telefone"
            readonly
          />
        </v-col>
        <v-col md="6" class="pb-0 pt-0">
          <v-text-field
            v-model="registration.mobilePhone"
            label="Celular"
            mask="(##) #####-####"
            readonly
          ></v-text-field>
        </v-col>
      </v-row>
    </v-col>
    <v-col md="6" class="pb-0 pt-0" v-if="this.registration.profile[0] === 'seller'">
      <v-text-field
        v-if="registration.captureFinancialData"
        v-model="registration.captureFinancialData"
        label="Solução de Captura"
        readonly
      ></v-text-field>
      <v-text-field v-else value="Sem Informação" label="Solução de Captura" readonly />
    </v-col>
    <v-col md="3" class="pb-0 pt-0" v-if="this.registration.profile[0] === 'seller'">
      <v-text-field
        v-if="registration.captureFinancialData === 'Máquina de Cartão (POS)'"
        v-model="registration.posQuantity"
        label="Quantidade de POS"
        readonly
      ></v-text-field>
    </v-col>
    <v-col md="12" class="titles-mobile mt-5">
      <v-col class="pa-0" md="6">
        <span class="titles" md="6">
          Dados do Representante
        </span>
      </v-col>
    </v-col>
    <v-col md="6" class="pb-0">
      <v-text-field
        v-model="registration.representative.name"
        label="Nome do Representante"
        readonly
      />
    </v-col>
    <v-col md="6" class="pb-0">
      <v-text-field
        v-model="registration.representative.motherName"
        label="Nome da Mãe do Representante"
        readonly
      />
    </v-col>
    <v-row class="pl-3 pr-3">
      <v-col md="6" cols="6" class="pb-0 pt-0">
        <v-text-field
          v-model="registration.representative.document"
          label="CPF do Representante"
          v-mask="'###.###.###-##'"
          readonly
        />
      </v-col>
      <v-col md="6" cols="6" class="pb-0 pt-0">
        <v-text-field v-model="birthDate" label="Data de Nascimento" readonly />
      </v-col>
    </v-row>
    <v-col md="12" class="titles-mobile mt-5">
      <v-col class="pa-0" md="6">
        <span class="titles" md="6">
          Endereço do Representante
        </span>
      </v-col>
    </v-col>
    <v-col md="6" class="pb-0">
      <v-text-field v-model="registration.representative.address.zipCode" label="CEP" readonly />
    </v-col>
    <v-col md="6" class="pb-0">
      <v-text-field
        v-model="registration.representative.address.street"
        label="Endereço"
        readonly
      />
    </v-col>
    <v-col md="6" class="pb-0 pt-0" v-if="$vuetify.breakpoint.smAndDown">
      <v-text-field
        v-model="registration.representative.address.neighborhood"
        label="Bairro"
        readonly
      />
    </v-col>
    <v-col md="6" class="pb-0 pt-0" v-if="$vuetify.breakpoint.smAndDown">
      <v-row>
        <v-col md="6" class="pb-0 pt-0">
          <v-text-field
            v-model="registration.representative.address.number"
            label="Número"
            readonly
          />
        </v-col>
        <v-col md="6" class="pb-0 pt-0">
          <v-text-field
            v-model="registration.representative.address.complement"
            label="Complemento"
            readonly
          />
        </v-col>
      </v-row>
    </v-col>
    <v-col md="6" class="pb-0 pt-0">
      <v-text-field v-model="registration.representative.address.city" label="Cidade" readonly />
    </v-col>
    <v-col md="6" class="pb-0 pt-0" v-if="$vuetify.breakpoint.mdAndUp">
      <v-text-field
        v-model="registration.representative.address.neighborhood"
        label="Bairro"
        readonly
      />
    </v-col>
    <v-col md="6" class="pb-0 pt-0">
      <v-row>
        <v-col md="6" class="pb-0 pt-0">
          <v-text-field
            v-model="registration.representative.address.state"
            label="Estado"
            readonly
          />
        </v-col>
        <v-col md="6" class="pb-0 pt-0">
          <v-text-field v-model="setCountry" label="País" readonly />
        </v-col>
      </v-row>
    </v-col>
    <v-col md="6" class="pb-0 pt-0" v-if="$vuetify.breakpoint.mdAndUp">
      <v-row>
        <v-col md="6" class="pb-0 pt-0">
          <v-text-field
            v-model="registration.representative.address.number"
            label="Número"
            readonly
          />
        </v-col>
        <v-col md="6" class="pb-0 pt-0">
          <v-text-field
            v-model="registration.representative.address.complement"
            label="Complemento"
            readonly
          />
        </v-col>
      </v-row>
    </v-col>
    <v-col md="12" class="titles-mobile mt-5">
      <v-col class="pa-0" md="6">
        <span class="titles" md="6">
          Informações de pagamento
        </span>
      </v-col>
    </v-col>
    <v-col md="6" class="pb-0">
      <v-text-field v-model="registration.bank" label="Banco" readonly />
    </v-col>
    <v-col md="6" class="pb-0 pt-0">
      <v-row>
        <v-col md="6">
          <v-text-field v-model="registration.agency" label="Agência" readonly />
        </v-col>
        <v-col md="6">
          <v-text-field v-model="concatAccountNumber" label="Conta e dígito" readonly />
        </v-col>
      </v-row>
    </v-col>
    <v-col md="6" class="pb-0 pt-0">
      <v-text-field v-model="accountType" label="Tipo de conta" readonly />
    </v-col>
    <v-col md="12" class="pa-0" v-if="showAccount">
      <v-col md="12" class="titles-mobile">
        <v-col class="pa-0" md="6">
          <span class="titles" md="6">
            Conta Digital Finago
          </span>
        </v-col>
      </v-col>
      <v-col md="6" class="pt-0">
        <v-row>
          <v-col md="6" class="pb-0">
            <v-text-field v-model="digitalAccount.branch" label="Agência" readonly></v-text-field>
          </v-col>
          <v-col cols="6" class="pb-0">
            <v-text-field v-model="digitalAccount.account" label="Conta" readonly></v-text-field>
          </v-col>
        </v-row>
      </v-col>
    </v-col>
    <v-col md="12" class="titles-mobile">
      <v-col class="pa-0" md="6">
        <span class="titles" md="6">
          Acesso Finago
        </span>
      </v-col>
    </v-col>
    <v-col md="6">
      <v-text-field v-model="registration.email" label="E-mail" readonly />
    </v-col>
    <v-col md="6">
      <v-text-field v-model="currentPassword" label="Senha" class="agency" readonly />
      <v-col @click="changePassword" md="12" class="link pa-0">
        Alterar minha senha>
      </v-col>
    </v-col>
  </v-row>
</template>

<script>
import { mask } from "vue-the-mask";
import { VMoney } from "v-money";
import { mapActions, mapGetters } from "vuex";
import accountTypes from "../login/registration/consts";
import service from "../../service/index";

export default {
  directives: {
    mask,
    money: VMoney
  },
  data() {
    return {
      setCountry: "Brasil",
      concatAdress: "",
      currentPassword: "*********",
      accountTypes,
      service
    };
  },
  computed: {
    ...mapGetters({
      registration: "Registration/getConsultRegistration",
      banks: "Registration/getBanks",
      digitalAccount: "DigitalAccount/getAccount",
      showAccount: "DigitalAccount/getShowAccount",
      role: "Auth/getRole"
    }),
    birthDate() {
      return this.$moment(this.registration.representative.birthDate).format("DD/MM/YYYY");
    },
    profile() {
      return this.registration.profile[0] === "supplier"
        ? "Fornecedor"
        : this.registration.profile[0] === "seller"
        ? "Lojista"
        : "";
    },
    accountType() {
      return this.registration.accountType === "P"
        ? "Poupança"
        : this.registration.accountType === "C"
        ? "Corrente"
        : "";
    },
    checkedRadio() {
      return this.registration.stateSubscription ? false : true;
    }
  },
  methods: {
    ...mapActions({
      setConsultRegistration: "Registration/setConsultRegistration",
      setBanks: "Registration/setBanks"
    }),
    bankName(item) {
      if (item.key === this.registration.bank) {
        this.registration.bank = item.value;
      }
    },

    inputMasks() {
      this.registration.phone = this.service.phoneFormat(this.registration.phone);
      this.registration.mobilePhone = this.service.mobileFormat(this.registration.mobilePhone);
      this.registration.document = this.service.documentFormat(this.registration.document);
      this.registration.monthlyIncome = this.service.formatCurrency(
        this.registration.monthlyIncome
      );
    },

    populateFormData() {
      const { address, number, neighborhood, city, state } = this.registration;
      this.concatAdress = `${address}, ${number} - ${neighborhood} - ${city} - ${state}`;
    },
    getAccountNumber() {
      const { accountDigitNumber, accountNumber } = this.registration;
      this.concatAccountNumber = `${accountNumber} -  ${accountDigitNumber}`;
    },
    changePassword() {
      const changePassword = "changePassword";
      return this.$router.push({ path: "/customer/${changePassword}" });
    }
  },

  async created() {
    await this.setConsultRegistration();
    await this.setBanks();
    this.banks.forEach(this.bankName);
    this.inputMasks();
    this.populateFormData();
    this.getAccountNumber();
  }
};
</script>

<style lang="scss" scoped>
@import "~@/assets/styles/commons/myRegistration.scss";
</style>
